import { IOption } from "@base/components/SelectInput/types";

export const week_days: IOption[] = [
	{ value: "1", label: "Domingo" },
	{ value: "2", label: "Segunda-feira" },
	{ value: "3", label: "Terça-feira" },
	{ value: "4", label: "Quarta-feira" },
	{ value: "5", label: "Quinta-feira" },
	{ value: "6", label: "Sexta-feira" },
	{ value: "7", label: "Sábado" },
];
