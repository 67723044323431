const subjects = [
	"Artes",
	"Biologia",
	"Ciências",
	"Educação Física",
	"Física",
	"Geografia",
	"História",
	"Matemática",
	"Português",
	"Química",
];

export default subjects;
